









































import { Vue, Component, Prop } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'
import moment from '@/plugins/moment'
import axios from 'axios'

@Component({
  components: {
    Loading
  }
})
export default class AdminTutorials extends Vue {
  @Prop({ type: String }) environmentId !: string

  loading = true
  fetched : any[] = []
  loadingTutorial = false

  get tutorials () {
    return this.fetched.map(t => ({
      ...t,
      time: moment.duration(t.time, 'seconds').humanize()
    }))
  }

  async mounted () {
    const { data } = await axios.get('https://tutoriales-sodlab.s3.us-east-1.amazonaws.com/interactive/index.json')
    this.fetched = data
    this.loading = false
  }

  async startTutorial (tutorialId : string) {
    this.loadingTutorial = true
    const { data } = await axios.get(`https://tutoriales-sodlab.s3.us-east-1.amazonaws.com/interactive/${encodeURIComponent(tutorialId)}.json`)
    // @ts-ignore
    const tutorial = this.$shepherd({
      useModalOverlay: true,
      defaultStepOptions: {
        scrollTo: true
      }
    })
    tutorial.addSteps(data.steps.map((t : any) => ({
      ...t,
      buttons: t.buttons?.map((b : any) => ({
        ...b,
        action: () => tutorial[b.action]()
      }))
    })))
    tutorial.addStep({
      text: data.endText || 'Tutorial Finalizado',
      buttons: [
        {
          text: 'Volver a tutoriales',
          action: async () => {
            await this.$router.push(`/${this.environmentId}/tutorials`)
            tutorial.next()
          }
        },
        {
          text: 'Finalizar',
          action: () => tutorial.next()
        }
      ]
    })
    await this.$router.push('/' + this.environmentId + (data.startPath || '/'))
    tutorial.start()
  }
}
